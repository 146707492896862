<template>
  <div class="morebtn">
     <div class="b-title NormalSmSize-bold">{{btitle}}</div>
     <span></span>
     <span></span>
     <span></span>
  </div>
</template>

<script>
  import config from '../http/config'
export default {
  name: 'morebtn',
  data() {
    return {
        btitle:'MORE'
    }
  },
  props:{
    title:String
  },
  created(){
      this.btitle=this.title;
  },
  computed: {
   
  },
  mounted(){

  },
  components: {
    
  },
  watch:{
      title(){
        this.btitle=this.title;
      }
  }
}
</script>

<style lang="scss" scoped>
  @media (max-width: 850px) {
      .morebtn{
        width: 1.8666666667rem;
        height: 0.5133333333rem;
        border:1px solid #9d9b9a;
        border-radius: 0.08rem;
        
      }
      .b-title{
          position:absolute;
          top: 0%;
          left:13%;
          line-height: .5133333333rem;
          letter-spacing: 0px;
          color:#9d9b9a;
          font-size: 0.32rem !important;
      }
      span{
        height: 1px;
        background: #9d9b9a;
        position: absolute;
      }
     
       span:nth-child(2){
          width: 8px;
          right: 5%;
          top: 34%;
          transform: rotate(38deg);
      }
       span:nth-child(3){
          width: 15px;
          right: 6%;
          top: 48%;
         
      }
       span:nth-child(4){
          width: 8px;
          right: 5%;
          top: 59%;
          transform: rotate(-38deg);
      }
  }
  @media (min-width: 850px) {
     .morebtn{
        width: 105px;
        height: 31px;
        border:2px solid #9d9b9a;
        border-radius: 6px;
          
      }
      .b-title{
          position:absolute;
          top: 0%;
          left:13%;
          line-height: 31px;
          letter-spacing: 3px;
          color:#9d9b9a;
      }
      span{
        height: 2px;
        background: #9d9b9a;
        position: absolute;
      }
     
       span:nth-child(2){
          width: 13px;
          right: 6%;
          top: 37%;
          transform: rotate(38deg);
      }
       span:nth-child(3){
          width: 21px;
          right: 8%;
          top: 48%;
         
      }
       span:nth-child(4){
          width: 13px;
          right: 6%;
          top: 60%;
          transform: rotate(-38deg);
      }
      .morebtn:hover,
      :focus {
        background: #eee
      };
  }
  .morebtn{
      // width: 80px;
      // height: 31px;
      // border:2px solid #9d9b9a;
      
      position: absolute;
      cursor: pointer;

      
      
  }
  
</style>
