<template>
  <div class="topbanner">
    <Swiper id="bannerBox" v-bind:options="swiperOption" ref="mySwiper">
      <swiper-slide class="swiper-slide" v-for="(item, index) in list" :key="index">
        <div class="banner">
          <!-- <img :src="item.msize" alt=""> -->
        <div class="SlideImg" :style="{background:'url(' + item.msize + ') no-repeat center center'}" @click="getURL(item.link)"></div>

        </div>
      </swiper-slide>
       <div class="swiper-pagination" slot="pagination"></div>
    </Swiper>

   <Swiper id="bannerBox-moblie" v-bind:options="swiperOptionMobile" ref="mySwiper">
      <swiper-slide class="swiper-slide" v-for="(item, index) in list" :key="index">
        <div class="banner">
          <img :src="item.tsize" alt="">
        </div>
      </swiper-slide>
       <div class="swiper-pagination" slot="pagination"></div>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'


export default {
  name: 'topbanner',
  data() {
    return {
      list: [
        {msize:'../images/banner1.jpg',tsize:'../images/phoneb1.jpg'},
        {msize:'../images/banner2.jpg',tsize:'../images/phoneb2.jpg'},
        {msize:'../images/banner3.jpg',tsize:'../images/phoneb3.jpg'},
        {msize:'../images/banner4.jpg',tsize:'../images/phoneb4.jpg'},
        {msize:'../images/banner5.jpg',tsize:'../images/phoneb5.jpg'},
       ], //轮换列表
      swiperOption: {
        notNextTick: true,
        direction: 'horizontal',
        grabCursor: false,
        setWrapperSize: true,
        slidesPerView: 1,
        mousewheel: false,
        mousewheelControl: false,
        autoHeight:false,
        // height: window.innerHeight,  
        // width:window.innerWidth,
        resistanceRatio: 0,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          bulletActiveClass:'swiper-pagination-acc'
        },
        on:{
          slideChange: ()=>{
            let swiper = this.$refs.mySwiper.swiper;
            // console.log(swiper.activeIndex); //滑动打印当前索引

          }
        }
      },
      swiperOptionMobile: {
        notNextTick: true,
        direction: 'horizontal',
        grabCursor: false,
        setWrapperSize: true,
        autoHeight: true,
        slidesPerView: 1,
        mousewheel: false,
        mousewheelControl: false,
        // height: window.innerHeight-55,  
        width:window.innerWidth,
        resistanceRatio: 0,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          bulletActiveClass:'swiper-pagination-acc'
        },
        on:{
          slideChange: ()=>{
            let swiper = this.$refs.mySwiper.swiper;
            // console.log(swiper.activeIndex); //滑动打印当前索引

          }
        }
      }
    }
  },
  created(){
    // this.list = [0,1,2,3,4,5,6,7,8,9];
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  mounted(){
    // this.swiper.slideTo(3, 1000, false); //手动跳到指定页
  },
  components: {
    Swiper,
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>

.topbanner{
  position: relative;
}


@media (max-width: 1080px){
  #bannerBox{
    display: none;
  }
  #bannerBox-moblie{
    display: block;
    background:#f3f3f4;
    height: auto;
  }
  .swiper-pagination{
    bottom: 4%;
  }
  .swiper-slide{
    width: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
@media (min-width: 1080px){
  #bannerBox{
    display: block;
    height: 100%;
  }
  #bannerBox-moblie{
    display: none;
  }
  .topbanner{
    width:100%;
    height: 100%;
  }
  .swiper-pagination{
     bottom: 4%;
  }
  .swiper-slide{
    width: 100%;
    height: 100% !important;
    .banner{
      height: 100%;
      width: 100% 
    }
    .SlideImg{
    width:100%;
    height:100%;
    background-size:cover !important;
  }
    img{
      height: 100%;
    }
  }

}

</style>
